import baseUrl from "../plugins/axios/axios.js";
import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth";
import alert from "./modules/alert";
import image from "./modules/image";
import profile from "./modules/profile";
import tournametns from "./modules/tournametns";
import tickets from "./modules/tickets";
import wallets from "./modules/wallets";
import payments from "./modules/payments";
import purchases from "./modules/purchases.js";
import landing from "./modules/landing";
import returnRoute from "./modules/returnRoute";
Vue.use(Vuex)

export default new Vuex.Store({
  mutations: {
  },
  actions: {
     /**
   *get main reports
   */
   getMainReports() {
    return new Promise((resolve, reject) => {
      baseUrl
        .get("/users/dashboard/main-report")
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          
          reject();
        });
    });
  },
  },
  getters:{
  },
  modules: {
    auth,
    alert,
    profile,
    tournametns,
    tickets,
    wallets,
    payments,
    purchases,
    landing,
    returnRoute,
    image
  }
})
